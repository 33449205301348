<template>
	<div class="grid" :class="modifierClass">
		<slot name="cell" />
	</div>
</template>

<script>
	export default {
		name: 'GridContainer',

		props: {
			columns: {
				type: Number,
			},
		},

		computed: {
			modifierClass() {
				return this.columns ? `grid--${this.columns}columns` : '';
			},
		},
	};
</script>

<style lang="scss" scoped>
	.grid {
		display: grid;
		grid-template-columns: 100%;
		grid-auto-rows: max-content;
		gap: var(--spacing-xl);
		padding: var(--spacing-xxl) 0;
		align-content: center;
		min-height: var(--page-height);

		@include breakpoint($xxxlarge) {
			gap: var(--spacing-lg) var(--spacing-xxl);
		}

		&--2columns {
			@include breakpoint($small-landscape) {
				grid-template-columns: repeat(2, 1fr);
			}

			@include breakpoint($large) {
				grid-template-columns: repeat(2, 1fr);
			}
		}

		&--3columns {
			@include breakpoint($large) {
				grid-template-columns: repeat(3, 1fr);
			}
		}

		&--4columns {
			@include breakpoint($small-landscape) {
				grid-template-columns: repeat(2, 1fr);
			}

			@include breakpoint($large) {
				grid-template-columns: repeat(4, 1fr);
			}
		}
	}
</style>
